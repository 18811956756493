import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import HeadingMain from '../components/HeadingMain'
import Newsletter from '../components/Newsletter'
import SEO from '../components/SEO'

import StyledLink from '../components/styles/StyledLink'

const Container = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  display: flex;
  margin-bottom: 90px;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  }
`

const Left = styled.div`
  position: relative;
  width: 50%;
  padding-right: 80px;
  @media (max-width: 1000px) {
    width: 100%;
    padding-left: ${p => p.theme.paddingOnSides};
    padding-right: ${p => p.theme.paddingOnSides};
    margin-bottom: 30px;
  }
`

const Right = styled.div`
  width: 50%;
  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 32px;
  }
`

const Heading = styled.h1`
  font-size: 30px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: ${p => p.theme.textColorDark};
  margin-bottom: 24px;
`

const Paragraph = styled.p`
  margin-bottom: 26px;
  p:not(:last-child) {
    margin-bottom: 26px;
  }
  a {
    position: relative;
    z-index: 2;
    :after {
      content: '';
      display: block;
      position: absolute;
      left: 0px;
      bottom: 1px;
      background-color: ${p => p.theme.primaryColorLighter};
      width: 100%;
      height: 6px;
      z-index: -1;
    }
  }
`

const PortraitContainer = styled.div`
  :first-child {
    margin-bottom: 80px;
    @media (max-width: 1000px) {
      margin-bottom: 60px;
    }
    @media (max-width: 800px) {
      margin-bottom: 40px;
    }
  }
`

const Portrait = styled(GatsbyImage)`
  width: 40vw;
  min-width: 100%;
  box-shadow: 0 12px 38px ${p => p.theme.lightGray};
`

const Copyright = styled.div`
  width: 40vw;
  min-width: 100%;
  text-align: right;
  position: relative;
  color: #b3b3b3;
  font-size: 14px;
  padding-right: 1px;
  padding-top: 1px;
  @media (max-width: 1000px) {
    font-size: 13px;
    padding-right: 8px;
  }
`

const WaterColor = styled(GatsbyImage)`
  position: absolute !important;
  width: 210px;
  opacity: 0.4;
  z-index: -1;
  top: -24px;
  left: -40px;
  @media (max-width: 1000px) {
    width: 170px;
    left: -5px;
    top: -20px;
  }
`

const WatercolorWrapper = styled.div`
  position: relative;
  margin-top: 48px;
`

class YogaRaumGols extends Component {
  render() {
    const { yogaImage, yogaImage2, preise, zeiten, watercolorImage } = this.props.data
    const preiseHtml = preise?.edges?.[0]?.node?.html
    const zeitenHtml = zeiten?.edges?.[0]?.node?.html
    return (
      <React.Fragment>
        <SEO
          title="YogaRaum Gols"
          description="Vino Vinyasa Yoga – Kombination von Körper, Geist und Genuss"
          image="https://www.weingut-goeschl.at/yoga-und-wein.jpg"
        />
        <HeadingMain mainHeading="YogaRaum Gols" />
        <Container>
          <Left>
            <WaterColor alt="" image={watercolorImage.childImageSharp.gatsbyImageData} />
            <Heading>YogaRaum Gols</Heading>
            <Paragraph>
              Im Oktober 2022 haben wir unseren neuen YogaRaum Gols am Weingut Göschl & Töchter eröffnet. Ein Raum zum
              Ankommen, Abschalten, Meditieren, Yogieren, Austauschen und Wohlfühlen. Hier laden wir ein zu wöchentlich
              stattfindenden Yogaklassen, und auch zu diversen Pop Up Veranstaltungen wie Yoga & Wein Events, Yoga &
              Live Musik und vieles mehr. Alle aktuellen Veranstaltungen sind{' '}
              <StyledLink style={{ whiteSpace: 'nowrap' }}>
                <Link to="/Termine/">hier</Link>
              </StyledLink>{' '}
              zu finden.
            </Paragraph>

            <WatercolorWrapper>
              <WaterColor alt="" image={watercolorImage.childImageSharp.gatsbyImageData} />
            </WatercolorWrapper>
            <Heading>Aktuelle Kurse</Heading>
            <Paragraph>
              Die Yogaeinheiten sind für alle Könnensstufen geeignet (für Neulinge als auch für Fortgeschrittene) und
              der Einstieg ist jederzeit möglich, solange Platz in der Gruppe ist. Die Einheiten sind eine Kombination
              aus aktivem, fließendem Vinyasa-Yoga und langsamerem Hatha-Yoga mit ausreichend Zeit für Meditation und
              Entspannung.
            </Paragraph>
            <Paragraph>Hin und wieder gibt`s im Anschluss auch ein Glas Wein zum Ausklingen.</Paragraph>
            {zeitenHtml && (
              <Paragraph as="div">
                <div dangerouslySetInnerHTML={{ __html: zeitenHtml }} />
              </Paragraph>
            )}
            <Paragraph>
              Bitte eine eigene Matte und bei Bedarf auch eine Decke mitbringen (es sind Reservematten vor Ort, sowie
              Decken, Polster etc.)
            </Paragraph>
            {preiseHtml && (
              <Paragraph as="div">
                <div dangerouslySetInnerHTML={{ __html: preiseHtml }} />
              </Paragraph>
            )}
          </Left>
          <Right>
            <PortraitContainer>
              <Portrait
                critical={true}
                image={yogaImage.childImageSharp.gatsbyImageData}
                alt="Yoga und Wein - Vino Vinyasa Yoga am Neusiedler See"
              />
            </PortraitContainer>
            <PortraitContainer>
              <Portrait
                critical={true}
                image={yogaImage2.childImageSharp.gatsbyImageData}
                alt="Yoga und Wein - Vino Vinyasa Yoga am Neusiedler See"
              />
              <Copyright>
                <a target="_blank" rel="noopener noreferrer" href="https://www.juliageiter.com/">
                  © Julia Geiter
                </a>
              </Copyright>
            </PortraitContainer>
          </Right>
        </Container>
        <Newsletter />
      </React.Fragment>
    )
  }
}

export default YogaRaumGols

export const query = graphql`
  {
    yogaImage: file(relativePath: { eq: "photos/yoga-raum.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    yogaImage2: file(relativePath: { eq: "photos/yoga-studio.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    watercolorImage: file(relativePath: { eq: "photos/background-watercolor.png" }) {
      childImageSharp {
        gatsbyImageData(width: 190, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
    preise: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/yoga-preise-info/" } }) {
      edges {
        node {
          id
          html
        }
      }
    }
    zeiten: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/yoga-zeiten-info/" } }) {
      edges {
        node {
          id
          html
        }
      }
    }
  }
`
